<template>
  <!-- 学徒详情 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)">
      <template slot="extra" v-if="info">
        <a-button>
          <router-link
            :to="{
              path: '/apprenticeTraining',
              query: { id: info.id }
            }"
          >
            培训管理
          </router-link>
        </a-button>
      </template>
    </a-page-header>
    <div class="main-content">
      <div class="body" v-if="info">
        <div class="body-left">
          <div class="user">
            <div class="avatar">
              <img class="img" :src="info.avatar" />
            </div>
            <div class="name">{{ info.realName }}</div>
            <!-- <a-tag color="green" v-if="info.train === 0">
              学徒状态：{{ trainText[info.train] }}
            </a-tag>
            <a-tag v-else>学徒状态：{{ trainText[info.train] }}</a-tag> -->
          </div>
        </div>

        <div class="body-right">
          <div class="text-content">
            <div class="content-item">
              <div class="content-item-header">
                <div class="title">学徒信息</div>
              </div>
              <div class="content-sub-title">概述</div>

              <div class="ul ul-1">
                <div class="li">
                  <span class="label">学徒姓名：</span>
                  <span>{{ info.realName }}</span>
                </div>
                <div class="li">
                  <span class="label">学徒学号：</span>
                  <span>{{ info.studentNumber }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label">学徒联系方式：</span>
                  <span>{{ info.mobile }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li address">
                  <span class="label">常住地址：</span>
                  <span>{{ info.address }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="text-content text-content-2" v-if="info.lector">
            <div class="content-item">
              <div class="content-item-header content-item-header-flex">
                <div class="title">培训信息</div>
                <a-button>
                  <router-link
                    :to="{
                      path: '/apprenticeTraining',
                      query: { id: info.id }
                    }"
                  >
                    培训管理
                  </router-link>
                </a-button>
              </div>
              <div class="content-sub-title">概述</div>

              <div class="ul ul-1">
                <div class="li">
                  <span class="label">理论讲师姓名：</span>
                  <span>{{ info.lector }}</span>
                </div>
                <div class="li">
                  <span class="label">实操讲师姓名：</span>
                  <span>{{ info.traing }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label">带训师傅姓名：</span>
                  <span>{{ info.visitiser }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="id-card-content">
            <div class="content-sub-title">
              <span>实名信息</span>
              <a-button type="link">
                更新
              </a-button>
            </div>
            <div class="content-item content-item-flex id-card">
              <div class="id-card-img">
                <div class="item">
                  <img class="img" :src="info.IDcardFrontUrl" />
                  <div class="text">身份证国徽面</div>
                </div>
                <div class="item item-2">
                  <img class="img" :src="info.IDcardBackUrl" />
                  <div class="text">身份证人像面</div>
                </div>
              </div>

              <a-divider class="divider-vertical" type="vertical" />

              <div class="id-card-text">
                <div class="text text-1">身份证号码：{{ info.IDcardNum }}</div>
                <div class="text">有效期：{{ info.IDcardEndTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let apprenticeId = ''
export default {
  data() {
    return {
      title: '',
      info: null,
      applyText: {
        0: '未转编',
        1: '转编师傅'
      },
      trainText: {
        0: '培训中',
        1: '培训完成',
        2: '培训中止'
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    apprenticeId = this.$route.query.id
    this.getApprenticeInfo()
  },
  methods: {
    getApprenticeInfo() {
      const data = {
        id: apprenticeId
      }
      this.$axios.getApprenticeInfo(data).then((res) => {
        this.info = res.data.data
      })
    }
  }
}
</script>
<style scoped>
.body {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.body-left,
.text-content,
.id-card-content {
  background-color: #fff;
  border-radius: 2px;
}

.body-left {
  width: 238px;
  height: 100%;
  padding: 32px;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.avatar {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 8px;
  border-radius: 3px;
  margin: 0 auto;
}

.name {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  margin: 8px 0;
}

.body-right {
  flex: 1;
  margin-left: 24px;
}

.text-content-2 {
  margin-top: 16px;
}

.text-content,
.id-card-content {
  padding: 20px 32px 24px 32px;
}

.id-card-content {
  margin-top: 16px;
}

.content-item-flex {
  display: flex;
  align-items: center;
}

.content-item-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-item-header .title {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.content-sub-title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.ul-1 {
  margin-top: 16px;
}

.ul:not(:last-child) {
  margin-bottom: 16px;
}

.ul .li {
  width: 240px;
  display: inline-block;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ul .li:not(:last-child) {
  margin-right: 46px;
}

.ul .label {
  font-weight: bold;
}

.ul .address {
  width: auto;
}

.area {
  margin-left: 56px;
}

.id-card {
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 16px;
}

.id-card-img {
  display: flex;
}

.id-card-img .item {
  width: 160px;
  height: 90px;
}

.id-card-img .item-2 {
  margin-left: 24px;
}

.id-card-img .text {
  text-align: center;
  line-height: 22px;
  margin-top: 8px;
}

.divider-vertical {
  height: 100px;
  margin: 10px 32px 10px 32px;
}

.id-card-text .text-1 {
  margin: 30px 0 16px 0;
}
</style>
